<template>
  <v-container fluid no-gutters app class="pa-0" :style="themeColors">
    <v-app-bar elevation="2" color="light">
      <v-app-bar-nav-icon
        data-testid="app-bar-nav-icon"
        @click.stop="drawer = !drawer"
        color="primary"
        :loading="loading"
      ></v-app-bar-nav-icon>

      <img
        :src="`/img/${getLogos.appBar}`"
        alt="Logotipo da empresa"
        style="max-width: 188px; max-height: 40px"
      />

      <AppBarEnvLabel
        v-if="configsEnv === 'homol' || configsEnv === 'dev'"
        :configsEnv="configsEnv"
      />

      <v-spacer></v-spacer>

      <BgMenu></BgMenu>

      <v-menu
        v-if="currentClientModule"
        bottom
        offset-y
        style="z-index: 1000 !important"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
            class="mr-2"
            :loading="loading"
            color="primary"
          >
            <v-icon color="primary" class="mr-2">{{
              currentClientModule.icon
            }}</v-icon>
            <span>{{ currentClientModule.name }}</span>
          </v-btn>
        </template>

        <v-list style="overflow-y: auto">
          <v-list-item-group active-class="primary--text text--accent-4">
            <a
              v-for="clientModule in getClientModules"
              :key="clientModule.name"
              class="text-decoration-none"
              :href="navigateClientModules(clientModule.subdomain)"
            >
              <v-layout align-center>
                <v-flex xs10>
                  <v-list-item
                    :class="
                      clientModule.subdomain === currentClientModule.subdomain
                        ? 'v-list-item--active'
                        : ''
                    "
                  >
                    <v-list-item-icon>
                      <v-icon
                        v-text="clientModule.icon"
                        color="primary"
                      ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="clientModule.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
                <v-flex xs2>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-layout justify-center>
                        <a
                          :href="navigateClientModules(clientModule.subdomain)"
                          target="_blank"
                          class="text-decoration-none"
                          v-on="on"
                        >
                          <v-icon
                            class="px-2"
                            color="primary"
                            style="margin-left: -8px !important"
                            small
                            >mdi-open-in-new</v-icon
                          >
                        </a>
                      </v-layout>
                    </template>
                    <span>Abrir em nova aba</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </a>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-progress-circular
        v-else
        indeterminate
        size="24"
        width="3"
        color="primary"
        class="ml-2 mr-4"
      ></v-progress-circular>

      <UserDropDownMenu />

      <v-divider vertical class="mr-3 ml-3" inset></v-divider>

      <a
        href="https://znap.com.br/"
        target="_blank"
        style="text-decoration: none"
        class="d-flex align-center"
      >
        <img
          src="/img/znap-app-bar.png"
          alt="Logotipo da ZNAP Technologies"
          style="max-width: 120px"
        />
      </a>
    </v-app-bar>

    <SideBar
      :loading="loading"
      :drawer="drawer"
      @update:drawer="updateDrawer"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import configs from "@/configs";
import setModuleRoute from "@/helpers/setModuleRoute";

import BgMenu from "@/components/BgMenu.vue";
import AppBarEnvLabel from "@/components/AppBarEnvLabel";
import UserDropDownMenu from "@/components/AppBar/UserDropDownMenu.vue";
import SideBar from "@/components/AppBar/SideBar.vue";

export default {
  name: "AppBar",

  components: {
    BgMenu,
    AppBarEnvLabel,
    UserDropDownMenu,
    SideBar,
  },

  props: {
    loading: { type: Boolean, required: true },
  },

  data() {
    return {
      drawer: false,
      group: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["getUser", "getClientModules", "getHash"]),
    ...mapGetters("theme", ["getLogos"]),

    themeColors() {
      return {
        "--primary-color": `${this.$vuetify.theme.themes.light.primary}`,
      };
    },

    currentClientModule() {
      const znapDomain = ".znaptech.com";
      const subdomain = `.${configs.moduleName}${znapDomain}`;

      if (!this.getClientModules) {
        return null;
      }

      return this.getClientModules.find((i) => i.subdomain === subdomain);
    },

    configsEnv() {
      return configs ? configs.env : null;
    },
  },

  watch: {
    // Observa mudanças na rota para ajustar o drawer conforme necessário
    $route(to) {
      this.openDrawerBasedOnRoute(to.path);
    },
  },

  mounted() {
    this.openDrawerSmoothly();
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 'vm' é uma referência à instância do Vue
      vm.openDrawerBasedOnRoute(to.path);
    });
  },

  methods: {
    updateDrawer(value) {
      this.drawer = value;
    },
    openDrawerBasedOnRoute(path) {
      if (path === "/") {
        this.$nextTick(() => {
          setTimeout(() => {
            this.drawer = true;
          }, 100); // Um pequeno delay para garantir uma transição suave
        });
      }
    },

    openDrawerSmoothly() {
      if (this.$route.path === "/") {
        // Aguarda a montagem do componente para iniciar a transição
        this.$nextTick(() => {
          setTimeout(() => {
            this.drawer = true;
          }, 100); // Um pequeno delay para garantir uma transição suave
        });
      }
    },

    navigateClientModules(subdomain) {
      const localEnv = window.location.href.includes(":80");
      const url = setModuleRoute(subdomain, localEnv, this.getHash);
      return url;
    },
    async getNotifications() {
      try {
        const payload = {
          filter: {
            conditions: [
              {
                AndOr: "AND",
                column: "id_user",
                operator: "=",
                value: this.getUser.id_user,
              },
            ],
          },
        };

        const res = await this.$http.post(
          this.$ipMessage + "notification/list",
          { ...payload }
        );

        if (res) {
          this.notifications = res.data.rows;
          this.notificationsNew = [];
          this.notifications.forEach((notification) => {
            notification.notification_status === 0
              ? this.notificationsNew.push(notification)
              : false;
          });
        }
      } catch (err) {
        this.$fnError(err);
      }
    },

    async tagAsRead(notification) {
      try {
        const payload = {
          description: notification.description,
          id_user: this.getUser.id_user,
          link: notification.link,
          notification_status: 1,
          id_module: 5,
        };

        const res = await this.$http.put(
          this.$ipMessage + "notification/edit/" + notification.id_notification,
          { ...payload }
        );

        if (res) {
          this.getNotifications();
        }
      } catch (err) {
        this.$fnError(err);
      }
    },

    async deleteNotifications(id) {
      try {
        const res = await this.$http.delete(
          this.$ipMessage + "notification/delete/" + id
        );

        if (res) {
          this.getNotifications();
        }
      } catch (err) {
        this.$fnError(err);
      }
    },
  },
};
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
}

.v-list-item--active {
  color: var(--primary-color) !important;
}

.v-navigation-drawer {
  will-change: initial;
  transform: none !important;
  transition-property: "width";
  z-index: 1500 !important;
}
</style>
