<template>
  <div>
    <v-divider class="mx-4" vertical inset></v-divider>
    <span
      v-if="configsEnv === 'homol'"
      class="text-uppercase headline font-weight-bold dark"
    >
      SandBox
    </span>

    <span
      v-if="configsEnv === 'dev'"
      class="text-uppercase headline font-weight-bold dark"
    >
      Dev
    </span>
  </div>
</template>

<script>
export default {
  name: "AppBarEnvLabel",

  props: {
    configsEnv: { type: String, required: true },
  },
};
</script>
