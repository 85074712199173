<template>
  <v-navigation-drawer
    v-model="computedDrawer"
    temporary
    app
    :style="navigationDrawerStyle"
  >
    <v-container fluid fill-height class="pa-0 ma-0">
      <v-layout column>
        <div class="d-flex">
          <v-app-bar-nav-icon
            @click.stop="computedDrawer = !computedDrawer"
            color="primary"
            :loading="loading"
            class="my-2 ml-1"
            min-width="48px"
            min-height="48px"
          ></v-app-bar-nav-icon>

          <div max-width="300" max-height="51">
            <img
              style="margin-top: 11px; max-width: 188px; max-height: 80px"
              :src="`/img/${getLogos.appBar}`"
              alt="Logomarca"
            />
          </div>
        </div>

        <v-divider></v-divider>

        <v-list>
          <div v-for="(item, index) in this.getMenu" :key="item.groupCode">
            <v-list-item-group v-model="selectionMenu">
              <v-list-item
                data-testid="group-menu"
                @click="setGroupMenu(item, index)"
                :class="{ 'v-list-item--active': selectedGroupMenu(item) }"
              >
                <v-list-item-icon>
                  <v-icon color="primary">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.groupName }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon>{{
                    item.expand ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>

            <v-expand-transition>
              <v-list-item-group
                v-show="item.expand"
                class="ml-14"
                v-model="selectionSubMenu"
                multiple
              >
                <a
                  data-testid="submenu"
                  v-for="menu in item.menus"
                  :key="menu.transactionCode"
                  :href="menu.frontend_route_path"
                  class="text-decoration-none"
                >
                  <v-layout align-center>
                    <v-flex xs10>
                      <v-list-item
                        @click="navigateMenu(menu)"
                        @click.prevent="
                          $nextTick(() => {
                            selectionSubMenu = [];
                          })
                        "
                      >
                        <v-list-item-icon
                          v-if="$route.path === menu.frontend_route_path"
                          class="mx-0"
                        >
                          <v-icon
                            color="primary"
                            style="margin-left: -8px !important"
                            >mdi-menu-right</v-icon
                          >
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title
                            class="text-subtitle-2 font-weight-regular"
                          >
                            {{ menu.label }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-flex>
                    <v-flex xs2>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-layout justify-center>
                            <a
                              :href="menu.frontend_route_path"
                              target="_blank"
                              class="text-decoration-none"
                              v-on="on"
                            >
                              <v-icon
                                class="px-2"
                                color="primary"
                                style="margin-left: -8px !important"
                                small
                                >mdi-open-in-new</v-icon
                              >
                            </a>
                          </v-layout>
                        </template>
                        <span>Abrir em nova aba</span>
                      </v-tooltip>
                    </v-flex>
                  </v-layout>
                </a>
              </v-list-item-group>
            </v-expand-transition>
          </div>
        </v-list>
        <v-spacer></v-spacer>
        <v-footer color="white">
          <v-layout align-center justify-center>
            <a :href="releaseURL" target="_blank" class="text-decoration-none">
              <p class="text-caption text-center grey--text text--darken-3">
                v. {{ appVersion }}
              </p>
            </a>
          </v-layout>
        </v-footer>
      </v-layout>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import configs from "@/configs";
import { version } from "../../../package";

export default {
  props: {
    loading: { type: Boolean, required: true },
    drawer: { type: Boolean, required: true },
  },
  data() {
    return {
      appVersion: version,
      selectionMenu: [],
      selectionGroupMenu: [],
      selectionSubMenu: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["getMenu"]),
    ...mapGetters("theme", ["getLogos"]),

    navigationDrawerStyle() {
      return { width: this.drawer ? "356px" : "0px" };
    },

    computedDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("update:drawer", value);
      },
    },

    releaseURL() {
      const release = version;
      const url = `https://github.com/Znap-Technologies/${configs.system.toLowerCase()}-${
        configs.moduleName
      }-releases/releases/tag/${release}`;
      return url;
    },
  },
  methods: {
    setGroupMenu(item) {
      this.$nextTick(() => {
        this.selectionMenu = [];
      });

      let i = this.selectionGroupMenu.indexOf(item);

      if (i === -1) {
        item.expand = true;
        this.selectionGroupMenu.push(item);
      } else {
        item.expand = false;
        this.selectionGroupMenu.splice(i, 1);
      }
    },

    selectedGroupMenu(item) {
      let active = false;
      item.menus.forEach((menu) => {
        if (this.$route.path === menu.frontend_route_path) {
          active = true;
        }
      });

      return active;
    },

    navigateMenu(menu) {
      if (this.$route.path !== menu.frontend_route_path) {
        this.$router.push(menu.frontend_route_path);
        this.closeAllExpandedMenus();
      }
    },
    closeAllExpandedMenus() {
      this.getMenu.forEach((menu) => {
        menu.expand = false;
      });

      this.selectionGroupMenu = [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
