import configs from "../configs";
import getModulePort from "./getModulePort";
const { env, system } = configs;

const setProtocol = (localEnv) => {
  return env === "local" || localEnv ? "http://" : "https://";
};

const setModule = () => {
  let moduleEnv = "";

  if (env === "homol") {
    moduleEnv = ".homol";
  }

  if (env === "dev") {
    moduleEnv = ".dev";
  }

  return `${system.toLowerCase()}${moduleEnv}`;
};

export default function setModuleRoute(subdomain, localEnv, hash) {
  const protocol = setProtocol(localEnv);
  let moduleName = "";
  let port = "";

  if (subdomain === ".znaptech.com/dashboards") {
    if (localEnv) {
      port = getModulePort("portal");
      return `${protocol}${system.toLowerCase()}.znaptech:${port}/report-gallery`;
    } else {
      const moduleEnv = setModule();
      return `${protocol}${moduleEnv}.znaptech.com/report-gallery`;
    }
  }

  if (localEnv) {
    moduleName = subdomain.split(".")[1];

    if (moduleName.includes("znaptech")) {
      moduleName = "";
      port = getModulePort("portal");
    } else {
      port = getModulePort(moduleName);
      moduleName = `.${moduleName}`;
    }

    return `${protocol}${system.toLowerCase()}${moduleName}.znaptech:${port}/?token=${hash}`;
  }

  const moduleEnv = setModule();
  return `${protocol}${moduleEnv}${subdomain}/?token=${hash}`;
}
