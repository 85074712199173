export default function getApiLocalPort(path) {
    if (path === "user") {
        return 8000;
    } else if (path === "client") {
        return 8001;
    } else if (path === "security") {
        return 8002;
    } else if (path === "organization") {
        return 8004;
    } else if (path === "message") {
        return 8003;
    } else if (path === "account") {
        return 8005;
    } else if (path === "approval.flow") {
        return 8006;
    } else if (path === "vendor") {
        return 8007;
    } else if (path === "unit") {
        return 8008;
    } else if (path === "product") {
        return 8009;
    } else if (path === "customer") {
        return 8010;
    } else if (path === "payment") {
        return 8011;
    } else if (path === "sales.area") {
        return 8012;
    } else if (path === "event") {
        return 8013;
    } else if (path === "sales.planning") {
        return 8014;
    } else if (path === "comment") {
        return 8015;
    } else if (path === "purchase") {
        return 8016;
    } else if (path === "purchase.planning") {
        return 8017;
    } else if (path === "opex.planning") {
        return 8019;
    } else if (path === "capex.planning") {
        return 8020;
    } else if (path === "pl.planning") {
        return 8022;
    } else if (path === "dynamic.report") {
        return 8021;
    } else if (path === "integration") {
        return 8023;
    } else if (path === "cash.flow") {
        return 8024;
    }
}
