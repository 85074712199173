<template>
  <v-dialog v-model="getIssueDialog" max-width="500px" persistent>
    <v-card style="position: relative">
      <v-card-title
        class="light--text pr-4"
        :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
        style="position: sticky; top: 0; width: 100%; z-index: 1000"
      >
        <span class="headline">Abrir chamado</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text dark min-width="48px" @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Fechar</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="py-4">
        <v-row>
          <v-col>
            <v-textarea
              v-model="description"
              label="Descrição"
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-end">
            <v-img
              v-if="screenshot"
              width="200"
              :src="screenshot"
              :aspect-ratio="16 / 9"
              class="relative"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="clearScreenshot()"
                    absolute
                    class="primary"
                    style="bottom: 8px; right: 8px"
                  >
                    <v-icon color="white">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Deletar print</span>
              </v-tooltip>
            </v-img>

            <v-btn v-else @click="takeScreenshot()" class="primary">
              <v-icon color="white" class="mr-2">mdi-camera</v-icon>
              Tirar print da tela
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <div class="light" style="position: sticky; bottom: 0; width: 100%">
        <v-divider class="mx-4"></v-divider>
        <v-card-actions class="px-6 py-6">
          <v-spacer></v-spacer>
          <v-btn text color="primary" class="mr-4" @click="close()">
            Cancelar
          </v-btn>
          <v-btn @click="submit()" color="primary" :disabled="!description">
            Enviar
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import html2canvas from "html2canvas";
import { mapGetters, mapMutations } from "vuex";
import configs from "@/configs";

export default {
  data() {
    return {
      description: "",
      screenshot: null,
    };
  },

  computed: {
    ...mapGetters("issue", [
      "getIssueDialog",
      "getLastError",
      "getLastRequest",
    ]),
    ...mapGetters("auth", ["getUser"]),
  },

  methods: {
    ...mapMutations("issue", ["setIssueDialog"]),

    submit() {
      console.log({
        id_user: this.getUser.id_user,
        module: configs.modulePath,
        lastError: this.getLastError,
        lastRequest: {
          ...this.getLastRequest,
          data: JSON.parse(this.getLastRequest.data),
        },
        description: this.description,
        screenshot: this.screenshot,
      });
    },

    close() {
      this.clearScreenshot();
      this.setIssueDialog(false);
    },

    takeScreenshot() {
      this.setIssueDialog(false);
      setTimeout(async () => {
        const canvas = await html2canvas(document.querySelector("html"));
        if (canvas) {
          const base64image = canvas.toDataURL("image/png");
          this.screenshot = base64image;
          this.setIssueDialog(true);
        }
      }, 500);
    },

    clearScreenshot() {
      this.screenshot = null;
    },
  },
};
</script>
