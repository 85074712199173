<template>
  <v-menu bottom left offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" color="primary">
        <v-icon color="primary">mdi-image-edit</v-icon>
      </v-btn>
    </template>

    <v-sheet class="pa-2">
      <div class="d-flex justify-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              @click="previousBg()"
              class="mr-2"
            >
              <v-icon color="primary">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Anterior</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" @click="nextBg()" class="mr-2">
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <span>Próximo</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              @click="randomBg()"
              class="mr-2"
            >
              <v-icon color="primary">mdi-shuffle</v-icon>
            </v-btn>
          </template>
          <span>Aleatório</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              @click="removeBg()"
              class="mr-2"
            >
              <v-icon color="primary">mdi-cancel</v-icon>
            </v-btn>
          </template>
          <span>Remover</span>
        </v-tooltip>
      </div>

      <!-- <div class="d-flex my-2">
                <v-img class="mr-2 cursor-pointer"
                    max-height="100"
                    max-width="166.811552"
                    :src="`img/bg/bg_${getLastBg}.webp`"
                    @click="setBg(getLastBg)"
                ></v-img>

                <v-img class="cursor-pointer"
                    max-height="100"
                    max-width="166.811552"
                    :src="`img/bg/bg_${getSecondLastBg}.webp`"
                    @click="setBg(getSecondLastBg)"
                ></v-img>
            </div>
            
            <div class="d-flex">
                <v-img class="mr-2 cursor-pointer"
                    max-height="100"
                    max-width="166.811552"
                    :src="`img/bg/bg_${getThirdLastBg}.webp`"
                    @click="setBg(getThirdLastBg)"
                ></v-img>

                <v-img class="cursor-pointer"
                    max-height="100"
                    max-width="166.811552"
                    :src="`img/bg/bg_${getFourthLastBg}.webp`"
                    @click="setBg(getFourthLastBg)"
                ></v-img>
            </div> -->
    </v-sheet>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "BgMenu",

  computed: {
    ...mapGetters("theme", [
      "getLastBg",
      "getSecondLastBg",
      "getThirdLastBg",
      "getFourthLastBg",
    ]),
  },

  methods: {
    ...mapMutations("theme", ["setBg"]),
    ...mapActions("theme", ["randomBg", "nextBg", "previousBg", "removeBg"]),
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>