<template>
  <v-menu bottom offset-y style="z-index: 1000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" class="mr-4">
        <v-badge
          avatar
          bottom
          :color="sessionBadgeColor"
          offset-x="18"
          offset-y="18"
        >
          <template v-if="getUser">
            <v-avatar v-if="getUser.photo">
              <v-img :src="getUser.photo" :aspect-ratio="1 / 1">
                <template v-slot:placeholder>
                  <v-skeleton-loader type="avatar"></v-skeleton-loader>
                </template>
              </v-img>
            </v-avatar>
            <v-avatar v-else>
              <v-icon color="primary" class="text-h3">
                mdi-account-circle
              </v-icon>
            </v-avatar>
          </template>
          <v-avatar v-else>
            <v-icon color="primary" class="text-h3">mdi-account-circle</v-icon>
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>

    <v-sheet max-width="350px">
      <v-list>
        <v-list-item-group>
          <v-list-item @click="$router.push('user-profile')">
            <v-list-item-icon class="mr-4">
              <template v-if="getUser">
                <v-avatar v-if="getUser.photo">
                  <v-img
                    :src="getUser.photo"
                    :alt="'Foto de ' + getUser.name"
                    :aspect-ratio="1 / 1"
                  />
                </v-avatar>
                <v-icon v-else color="primary" x-large
                  >mdi-account-circle</v-icon
                >
              </template>
              <v-icon v-else color="primary" x-large>mdi-account-circle</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-h6">{{
                getUser ? getUser.name : ""
              }}</v-list-item-title>
              <v-list-item-title class="text-caption">{{
                getUser ? getUser.email : ""
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-row class="py-2 px-4">
            <v-col class="d-flex align-center">
              <v-icon small color="primary" class="mr-2">mdi-clock</v-icon>

              <span class="text-caption">{{ `${tokenExp()}` }}</span>

              <v-spacer></v-spacer>

              <v-btn x-small text color="primary" @click="renewSession()">
                Renovar
              </v-btn>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-list-item @click="endSession()">
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      sessionBadgeColor: "success",
      now: new Date(),
      allowRenewSession: true,
      renewSessionInterval: null,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "getUser",
      "getTokenExp",
      "getHash",
      "getEnableSessionExpiredDialog",
    ]),
  },
  mounted() {
    const oneMinuteInMs = 60000;

    this.renewSessionInterval = setInterval(() => {
      this.now = Date.now();
    }, oneMinuteInMs);
  },
  beforeUnmount() {
    clearInterval(this.renewSessionInterval);
  },
  methods: {
    ...mapMutations("auth", ["setSessionExpiredDialog"]),
    ...mapActions("auth", ["logout", "hashAuthenticate"]),
    async renewSession() {
      let hash = this.getHash;

      const auth = await this.hashAuthenticate(hash);
      if (auth === "success") {
        this.$toast.success("Sessão renovada com sucesso");
      } else {
        let err = auth;
        this.$fnError(err);
      }
    },
    async endSession() {
      return this.logout();
    },
    tokenExp() {
      if (this.getTokenExp && !this.isValidToken(this.getTokenExp)) {
        if (this.getEnableSessionExpiredDialog)
          this.setSessionExpiredDialog(true);

        return "Sessão expirada";
      }

      const durationMs = this.getTokenExp * 1000 - this.now;

      const duration = {
        hours: Math.floor(durationMs / 3600000),
        minutes: Math.floor((durationMs % 3600000) / 60000),
      };

      const hours = duration.hours.toString().padStart(2, "0");
      const minutes = duration.minutes.toString().padStart(2, "0");

      const time = hours <= 0 ? `${minutes}m` : `${hours}h ${minutes}m`;

      this.setSessionBadgeColor(hours, minutes);

      const renewUserSession =
        duration.hours < 1 && duration.minutes < 5 && this.allowRenewSession;

      const oneSecondInMs = 1000;
      const tokenAlreadyExpire = durationMs < oneSecondInMs;

      if (renewUserSession && !tokenAlreadyExpire) {
        this.allowRenewSession = false;

        this.renewSession().then(() => {
          this.allowRenewSession = true;
        });
      }

      return `Sessão expira em ${time}`;
    },

    setSessionBadgeColor(hours, minutes) {
      if (!hours || !minutes) {
        return (this.sessionBadgeColor = "error");
      }

      let h = parseInt(hours);
      let m = parseInt(minutes);

      if (h > 0) return (this.sessionBadgeColor = "success");

      if (m >= 10) {
        return (this.sessionBadgeColor = "success");
      }

      if (m < 10 && m > 5) {
        return (this.sessionBadgeColor = "warning");
      }

      this.sessionBadgeColor = "error";
    },
    isValidToken(tokenExp) {
      const now = Math.floor(Date.now() / 1000);
      return now - tokenExp <= 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>
